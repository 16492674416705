import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import FcraForm from './FcraForm';
import {setFcraAccept} from '../../actions/fcra';
import utils from '../../helpers/utils';

type Props = {
  fcra?: $TSFixMe;
  showErrorMsg: boolean;
  showFcraColp: boolean;
  localizedStringBundle: StringMap;
  isAgent: boolean;
};

const FcraModal = ({showErrorMsg, showFcraColp, localizedStringBundle, isAgent}: Props) => {
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      utils.resizeIframe(undefined, document.body.clientHeight + 600);
    }, 500);
  });

  const handleSubmit = (e: $TSFixMe) => {
    e.preventDefault();
    dispatch(setFcraAccept());
  };

  const renderFcraErrorMsg = () => {
    return (
      <div className="py-24">
        <i className="material-icons error-color block text-center text-7xl text-danger">error_outline</i>
        <p className="m-5 block text-center text-2xl text-primary">{localizedStringBundle.COMMON_ERR_MSG}</p>
      </div>
    );
  };

  const renderFcraContent = (checked: $TSFixMe, setChecked: $TSFixMe) => {
    // Provide custom text message and color for subtitle shown in agent flow (explain that app access is blocked)
    const modalSubTitle = isAgent
      ? localizedStringBundle.FCRA_MODAL_SUBTITLE_AGENT
      : localizedStringBundle.FCRA_MODAL_SUBTITLE;
    const subtitleClassName = classNames(
      'block my-4 text-xl font-semibold text-center md:text-2xl',
      isAgent ? 'text-danger' : 'text-primary'
    );

    return (
      <>
        <div className="mx-4 my-5 text-center text-2xl font-semibold text-primary md:mx-0 md:text-4xl">
          {localizedStringBundle.FCRA_MODAL_TITLE}
          <span className={subtitleClassName}> {modalSubTitle}</span>
        </div>

        <div className="mx-4 my-8 flex md:mx-8 md:my-16">
          <input
            className="mt-2"
            type="checkbox"
            id="fcraCheck"
            checked={checked}
            disabled={isAgent}
            onChange={() => setChecked(!checked)}
          />
          <label
            className="ml-2 text-left leading-normal text-primary"
            htmlFor="fcraCheck"
            dangerouslySetInnerHTML={{__html: localizedStringBundle.FCRA_ACCEPT_CONTENT_TEXT}}
          />
        </div>
        <span
          className="mx-8 my-8 block text-primary md:mx-18 md:my-16"
          dangerouslySetInnerHTML={{__html: localizedStringBundle.FCRA_ACCEPT_TEXT}}
        />
      </>
    );
  };

  if (showFcraColp) {
    return (
      <div className="w-auto flex-grow">
        <div className="absolute inset-0 border-none bg-surface text-primary">
          <div className="md:m-32">
            {showErrorMsg ? (
              renderFcraErrorMsg()
            ) : (
              <>
                {renderFcraContent(checked, setChecked)}
                <FcraForm
                  // @ts-expect-error TS(2322) FIXME: Type '{ checkBoxSelected: boolean; onSubmit: (e: a... Remove this comment to see the full error message
                  checkBoxSelected={checked}
                  onSubmit={handleSubmit}
                  localizedStringBundle={localizedStringBundle}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default FcraModal;
