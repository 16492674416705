/* global EfxConsumerApiConstants EfxConsumerApiClient */

import _get from 'lodash/get';
import _has from 'lodash/has';
import {buildCreditScoreData} from '../helpers/buildCreditScoreData';
import {buildCreditReportData} from '../helpers/buildCreditReportData';
import utils from '../helpers/utils';
import {api} from '../helpers/api';
import {
  getAccountGuidAndMockEndpointStatus,
  getMockCreditScoreLatest,
  getMockCreditScoreHistory,
  getMockCreditReportLatest,
  getMockCreditReportLatestByType,
  getMockCreditReportById,
  getMockCreditReportHistory,
} from './creditMock';

//Common
export const SET_PROVIDER_BUREAU_DATA = 'SET_PROVIDER_BUREAU_DATA';

//Equifax
export const CREDIT_SCORE = 'CREDIT_SCORE';
export const CREDIT_SCORE_STATUS = 'CREDIT_SCORE_STATUS';
export const CREDIT_SCORE_HISTORY = 'CREDIT_SCORE_HISTORY';
export const CREDIT_SCORE_HISTORY_STATUS = 'CREDIT_SCORE_HISTORY_STATUS';
export const CREDIT_REPORT = 'CREDIT_REPORT';
export const CREDIT_REPORT_STATUS = 'CREDIT_REPORT_STATUS';
export const CREDIT_REPORT_ABSTRACT = 'CREDIT_REPORT_ABSTRACT';
export const CREDIT_REPORT_ABSTRACT_STATUS = 'CREDIT_REPORT_ABSTRACT_STATUS';
export const EQUIFAX_STATUS = 'EQUIFAX_STATUS';
export const DOWNLOAD_REPORT_STATUS = 'DOWNLOAD_REPORT_STATUS';
export const CREDIT_FULFILLMENT_REQUEST = 'CREDIT_FULFILLMENT_REQUEST';
export const CREDIT_FULFILLMENT_SUCCESS = 'CREDIT_FULFILLMENT_SUCCESS';
export const CREDIT_FULFILLMENT_FAILURE = 'CREDIT_FULFILLMENT_FAILURE';
export const CREDIT_PULLINFO_REQUEST = 'CREDIT_PULLINFO_REQUEST';
export const CREDIT_PULLINFO_SUCCESS = 'CREDIT_PULLINFO_SUCCESS';
export const CREDIT_PULLINFO_FAILURE = 'CREDIT_PULLINFO_FAILURE';
export const CREDIT_ON_DEMAND = 'CREDIT_ON_DEMAND';
export const CLEAR_FULFILLMENT_INFO = 'CLEAR_FULFILLMENT_INFO';

//TransUnion
export const TU_REPORT_HISTORY_REQUEST = 'REPORT_HISTORY_REQUEST';
export const TU_REPORT_HISTORY_SUCCESS = 'REPORT_HISTORY_SUCCESS';
export const TU_REPORT_HISTORY_FAILURE = 'REPORT_HISTORY_FAILURE';
export const TU_REPORT_REQUEST = 'REPORT_REQUEST';
export const TU_REPORT_SUCCESS = 'REPORT_SUCCESS';
export const TU_REPORT_FAILURE = 'REPORT_FAILURE';

//Common
export function setProviderBureauData(bureau: string, creditMax: $TSFixMe) {
  return {
    type: SET_PROVIDER_BUREAU_DATA,
    bureau,
    creditMax,
  };
}

//Equifax

export function getCreditScoreEquifax(
  clientLibraryInstance: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe
) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'loading'});

    try {
      const response = await clientLibraryInstance.getCreditScoreLatest();
      const creditScoreData = buildCreditScoreData(response, localizedStringBundle, langCode, 'EFX');
      dispatch({type: 'CREDIT_SCORE', credit_score: creditScoreData});
      dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'success'});
    } catch (error: $TSFixMe) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'error', status_text: error.statusText || ''});
      throw error;
    }
  };
}
export function getCreditScoreMock(accountGuid: $TSFixMe, localizedStringBundle: StringMap, langCode: $TSFixMe) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'loading'});

    try {
      const response = await getMockCreditScoreLatest(accountGuid);

      const creditScoreData = buildCreditScoreData(response, localizedStringBundle, langCode, 'EFX');
      dispatch({type: 'CREDIT_SCORE', credit_score: creditScoreData});
      dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'success'});
    } catch (error: $TSFixMe) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({type: 'CREDIT_SCORE_STATUS', credit_score_status: 'error', status_text: error.statusText || ''});
      throw error;
    }
  };
}

export function getCreditScoreHistoryEquifax(clientLibraryInstance: $TSFixMe) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_SCORE_HISTORY_STATUS', credit_score_history_status: 'loading'});

    try {
      const response = await clientLibraryInstance.getCreditScoresHistory('US_EFX');

      dispatch({type: 'CREDIT_SCORE_HISTORY', credit_score_history: response});
      dispatch({
        type: 'CREDIT_SCORE_HISTORY_STATUS',
        credit_score_history_status: 'success',
      });
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_SCORE_HISTORY_STATUS',
        credit_score_history_status: 'error',
        credit_score_history_error: error,
      });
      throw error;
    }
  };
}

export function getCreditScoreHistoryMock(accountGuid: $TSFixMe) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_SCORE_HISTORY_STATUS', credit_score_history_status: 'loading'});

    try {
      const response = await getMockCreditScoreHistory(accountGuid);

      dispatch({type: 'CREDIT_SCORE_HISTORY', credit_score_history: response});
      dispatch({
        type: 'CREDIT_SCORE_HISTORY_STATUS',
        credit_score_history_status: 'success',
      });
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_SCORE_HISTORY_STATUS',
        credit_score_history_status: 'error',
        credit_score_history_error: error,
      });
      throw error;
    }
  };
}
/**
 * Fetches and processes the credit report data.
 *
 * This function retrieves the latest credit report and its history, processes the data,
 * and dispatches the appropriate actions to update the state. It also implements caching
 * to avoid redundant network requests for the same report IDs.
 *
 * @param clientLibraryInstance - The instance of the client library used to make API calls.
 * @param localizedStringBundle - A bundle of localized strings for formatting the credit report data.
 * @param langCode - The language code for localization (default is 'en').
 * @param is3BUser - A flag indicating if the user is a 3B user (default is false).
 * @param creditPullInfo - Information about the credit pull.
 *
 * @returns A thunk function that performs the asynchronous operations and dispatches actions.
 */
export function getCreditReportEquifax(
  clientLibraryInstance: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode = 'en',
  is3BUser = false,
  creditPullInfo: $TSFixMe
) {
  const startTime = Date.now();
  const cache: {[key: string]: any} = {}; // Initialize cache

  // Helper function to get cached report or fetch if not in cache
  const getCachedOrFetchReport = async(id: string | null, fetchFn: () => Promise<any>) => {
    if (!id) return null;

    if (cache[id]) {
      return cache[id];
    }
    const report = await fetchFn();
    cache[id] = report;
    return report;
  };

  return async(dispatch: $TSFixMe) => {
    let creditReportLatestResponse = null;
    let creditReportHistoryResponse = null;

    // Get the latest credit report
    try {
      // Indicate that the credit report fetching process has started
      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});

      // Fetch the latest credit report from the client library
      creditReportLatestResponse = await clientLibraryInstance.getCreditReportLatest();

      // Extract the report ID from the latest credit report response
      const reportId = creditReportLatestResponse?.id;

      if (reportId) {
        // Add the latest credit report to the cache using the report ID as the key
        cache[reportId] = creditReportLatestResponse;
      }
    } catch (error) {
      // Log the error information and dispatch an error status
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_STATUS',
        credit_report_status: 'error',
        credit_report_error: error,
        report_type: 'latest',
      });
    }

    // Process the latest credit report data
    let latestCreditReport = buildCreditReportData(creditReportLatestResponse, localizedStringBundle, langCode, 'EFX');

    // Get the latest 3B credit report
    try {
      // Check if the latest credit report has exactly one provider view, the user is a 3B user,
      // and a fulfilled 3B report is available
      // NOTE: We could refactor out this logic by having TU/EXP tabs depend on latest_3B_credit_report instead of credit_report

      if (
        Array.isArray(creditReportLatestResponse?.providerViews || []) &&
        creditReportLatestResponse?.providerViews?.length === 1 &&
        is3BUser &&
        creditPullInfo.isFulfilled3BReportAvailable
      ) {
        // Fetch the latest 3B credit report from the client library
        const creditReportLatestByTypeResponse = await clientLibraryInstance.getCreditReportLatestByType('US_3B');

        // Build the credit report data for the fetched 3B credit report
        const creditReportLatestByTypeData = buildCreditReportData(
          creditReportLatestByTypeResponse,
          localizedStringBundle,
          langCode,
          'EFX'
        );

        // Get the list of providers from the fetched 3B credit report data, excluding 'EFX' provider
        const providersToMerge = _get(creditReportLatestByTypeData, 'latest.providers', []).filter(
          (bureau: $TSFixMe) => bureau.provider !== 'EFX'
        );

        // Merge the providers from the fetched 3B credit report data into the latest credit report response
        if (_has(latestCreditReport, 'latest.providers')) {
          creditReportLatestResponse.providerViews = [
            ..._get(latestCreditReport, 'latest.providers', []),
            ...providersToMerge,
          ];
        } else {
          creditReportLatestResponse = creditReportLatestByTypeData;
        }

        latestCreditReport = buildCreditReportData(creditReportLatestResponse, localizedStringBundle, langCode, 'EFX');

        // Extract the report ID from the fetched 3B credit report response
        const creditReportLatestByTypeId = creditReportLatestByTypeResponse?.id;

        if (creditReportLatestByTypeId) {
          // Add the fetched 3B credit report to the cache using the report ID as the key
          cache[creditReportLatestByTypeId] = creditReportLatestByTypeResponse;
        }
      } else {
        latestCreditReport = buildCreditReportData(creditReportLatestResponse, localizedStringBundle, langCode, 'EFX');
      }
    } catch (error) {
      // Log the error information and dispatch an error status
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_STATUS',
        credit_report_status: 'error',
        credit_report_error: error,
        report_type: 'US_3B',
      });
    }

    try {
      // Fetch the credit report history
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});
      creditReportHistoryResponse = await clientLibraryInstance.getCreditReportAbstract();
      dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: creditReportHistoryResponse});
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_ABSTRACT_STATUS',
        credit_report_abstract_status: 'error',
        credit_report_abstract_status_error: error,
      });
    }

    try {
      // Sort the credit reports by generated date
      const sortedCreditReports = creditReportHistoryResponse?.sort(
        (a: {generatedDate: string | number | Date}, b: {generatedDate: string | number | Date}) => {
          return new Date(b.generatedDate).getTime() - new Date(a.generatedDate).getTime();
        }
      );

      // Filter the credit reports by type
      const filteredEFXCreditReports = sortedCreditReports.filter(
        (report: {reportType: string}) => report.reportType === 'US_3B' || report.reportType === 'US_EFX'
      );

      const filtered3BCreditReports = sortedCreditReports.filter(
        (report: {reportType: string}) => report.reportType === 'US_3B'
      );

      // Get the latest and previous credit report information
      const latest3BCreditReportInfo =
        filtered3BCreditReports && filtered3BCreditReports.length > 0 ? filtered3BCreditReports[0] : null;

      const previousEFXCreditReportInfo =
        filteredEFXCreditReports && filteredEFXCreditReports.length > 1 ? filteredEFXCreditReports[1] : null;

      const previous3BCreditReportInfo =
        filtered3BCreditReports && filtered3BCreditReports.length > 1 ? filtered3BCreditReports[1] : null;

      const latest3BCreditReportId = latest3BCreditReportInfo?.id;
      const previousEFXCreditReportId = previousEFXCreditReportInfo ? previousEFXCreditReportInfo.id : null;
      const previous3BCreditReportId = previous3BCreditReportInfo ? previous3BCreditReportInfo.id : null;

      // Fetch the latest 3B credit report
      const latest3BCreditReport = await getCachedOrFetchReport(latest3BCreditReportId, () => {
        return clientLibraryInstance.getCreditReport(latest3BCreditReportId);
      });

      // Fetch the previous EFX credit report
      const previousEFXCreditReportResponse = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
        ? await getCachedOrFetchReport(previousEFXCreditReportId, () =>
            clientLibraryInstance.getCreditReport(previousEFXCreditReportId)
          )
        : {};

      const previousEFXCreditReport = buildCreditReportData(
        previousEFXCreditReportResponse,
        localizedStringBundle,
        langCode,
        'EFX'
      );

      // Fetch the previous 3B credit report
      const previous3BCreditReport = window.REACT_APP_ENABLE_CREDIT_INSIGHTS_V2
        ? await getCachedOrFetchReport(previous3BCreditReportId, () =>
            clientLibraryInstance.getCreditReport(previous3BCreditReportId)
          )
        : {};

      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'success'});
      dispatch({
        type: 'CREDIT_REPORT',
        credit_report: latestCreditReport,
        latest_3B_credit_report: latest3BCreditReport,
        previous_EFX_credit_report: previousEFXCreditReport,
        previous_3B_credit_report: previous3BCreditReport,
      });
    } catch (error) {
      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'error', credit_report_error: error});
    }
  };
}

export function getCreditReportMock(
  accountGuid: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode = 'en',
  is3BUser = false,
  creditPullInfo: $TSFixMe
) {
  const startTime = Date.now();
  const cache: {[key: string]: any} = {}; // Initialize cache

  // Helper function to get cached report or fetch if not in cache
  const getCachedOrFetchReport = async(id: string | null, fetchFn: () => Promise<any>) => {
    if (!id) return null;

    if (cache[id]) {
      return cache[id];
    }
    const report = await fetchFn();
    cache[id] = report;
    return report;
  };

  return async(dispatch: $TSFixMe) => {
    let creditReportLatestResponse = null;
    let creditReportHistoryResponse = null;

    try {
      // Fetch the latest credit report
      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});
      creditReportLatestResponse = await getMockCreditReportLatest(accountGuid);

      // Extract the id from the response
      const reportId = creditReportLatestResponse?.id;

      if (reportId) {
        // Add the report to the cache using the id
        cache[reportId] = creditReportLatestResponse;
      }
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'error', credit_report_error: error});
    }

    try {
      // Fetch the credit report history
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});
      creditReportHistoryResponse = await getMockCreditReportHistory(accountGuid);
      dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: creditReportHistoryResponse});
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_ABSTRACT_STATUS',
        credit_report_abstract_status: 'error',
        credit_report_abstract_status_error: error,
      });
    }

    // Process the latest credit report data
    const latestCreditReport = buildCreditReportData(
      creditReportLatestResponse,
      localizedStringBundle,
      langCode,
      'EFX'
    );

    // Sort the credit reports by generated date
    const sortedCreditReports = creditReportHistoryResponse?.sort(
      (a: {generatedDate: string | number | Date}, b: {generatedDate: string | number | Date}) => {
        return new Date(b.generatedDate).getTime() - new Date(a.generatedDate).getTime();
      }
    );

    // Filter the credit reports by type
    const filteredEFXCreditReports = sortedCreditReports.filter(
      (report: {reportType: string}) => report.reportType === 'US_3B' || report.reportType === 'US_EFX'
    );

    const filtered3BCreditReports = sortedCreditReports.filter(
      (report: {reportType: string}) => report.reportType === 'US_3B'
    );

    // Get the latest and previous credit report information
    const latest3BCreditReportInfo =
      filtered3BCreditReports && filtered3BCreditReports.length > 0 ? filtered3BCreditReports[0] : null;

    const previousEFXCreditReportInfo =
      filteredEFXCreditReports && filteredEFXCreditReports.length > 1 ? filteredEFXCreditReports[1] : null;

    const previous3BCreditReportInfo =
      filtered3BCreditReports && filtered3BCreditReports.length > 1 ? filtered3BCreditReports[1] : null;

    const latest3BCreditReportId = latest3BCreditReportInfo?.id;
    const previousEFXCreditReportId = previousEFXCreditReportInfo ? previousEFXCreditReportInfo.id : null;
    const previous3BCreditReportId = previous3BCreditReportInfo ? previous3BCreditReportInfo.id : null;

    // Fetch the latest 3B credit report
    const latest3BCreditReport = await getCachedOrFetchReport(latest3BCreditReportId, () =>
      getMockCreditReportById(accountGuid, latest3BCreditReportId)
    );

    // Fetch the previous EFX credit report
    const previousEFXCreditReportResponse = await getCachedOrFetchReport(previousEFXCreditReportId, () =>
      getMockCreditReportById(accountGuid, previousEFXCreditReportId)
    );

    const previousEFXCreditReport = buildCreditReportData(
      previousEFXCreditReportResponse,
      localizedStringBundle,
      langCode,
      'EFX'
    );

    // Fetch the previous 3B credit report
    const previous3BCreditReport = await getCachedOrFetchReport(previous3BCreditReportId, () =>
      getMockCreditReportById(accountGuid, previous3BCreditReportId)
    );

    // Dispatch the appropriate action based on the report type and user status
    if (
      creditReportLatestResponse &&
      Array.isArray(creditReportLatestResponse.providerViews) &&
      creditReportLatestResponse.providerViews.length === 1 &&
      is3BUser &&
      creditPullInfo.isFulfilled3BReportAvailable
    ) {
      dispatch(
        await getCreditReportByTypeMock(
          accountGuid,
          localizedStringBundle,
          langCode,
          'US_3B',
          latestCreditReport,
          latest3BCreditReport,
          previousEFXCreditReport,
          previous3BCreditReport
        )
      );
    } else {
      dispatch({
        type: 'CREDIT_REPORT',
        credit_report: latestCreditReport,
        latest_3B_credit_report: latest3BCreditReport,
        previous_EFX_credit_report: previousEFXCreditReport,
        previous_3B_credit_report: previous3BCreditReport,
      });
    }
  };
}

export async function getCreditReportByTypeEquifax(
  clientLibraryInstance: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  type: $TSFixMe,
  latestEFXCreditReport: $TSFixMe,
  latest3BCreditReport: $TSFixMe,
  previousEFXCreditReport: $TSFixMe,
  previous3BCreditReport: $TSFixMe
) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    try {
      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});
      const creditReportLatestByTypeResponse = await clientLibraryInstance.getCreditReportLatestByType(type);

      const creditReportData = buildCreditReportData(
        creditReportLatestByTypeResponse,
        localizedStringBundle,
        langCode,
        'EFX'
      );
      const latestProviders = _get(latestEFXCreditReport, 'latest.providers', []);
      const providersToMerge = _get(creditReportData, 'latest.providers', []).filter(
        (bureau: $TSFixMe) => bureau.provider !== 'EFX'
      );
      if (_has(latestEFXCreditReport, 'latest.providers')) {
        latestEFXCreditReport.latest.providers = [...latestProviders, ...providersToMerge];
      } else {
        latestEFXCreditReport = creditReportData;
      }

      dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'success'});

      return dispatch({
        type: 'CREDIT_REPORT',
        credit_report: latestEFXCreditReport,
        latest_3B_credit_report: latest3BCreditReport,
        previous_EFX_credit_report: previousEFXCreditReport,
        previous_3B_credit_report: previous3BCreditReport,
      });
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      return dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'error', credit_report_error: error});
    }
  };
}

export async function getCreditReportByTypeMock(
  accountGuid: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  type: $TSFixMe,
  latestEFXCreditReport: $TSFixMe,
  latest3BCreditReport: $TSFixMe,
  previousEFXCreditReport: $TSFixMe,
  previous3BCreditReport: $TSFixMe
) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'loading'});

    try {
      const creditReportLatestByTypeResponse = await getMockCreditReportLatestByType(accountGuid, type);

      const creditReportData = buildCreditReportData(
        creditReportLatestByTypeResponse,
        localizedStringBundle,
        langCode,
        'EFX'
      );
      const latestProviders = _get(latestEFXCreditReport, 'latest.providers', []);
      const providersToMerge = _get(creditReportData, 'latest.providers', []).filter(
        (bureau: $TSFixMe) => bureau.provider !== 'EFX'
      );
      if (_has(latestEFXCreditReport, 'latest.providers')) {
        latestEFXCreditReport.latest.providers = [...latestProviders, ...providersToMerge];
      } else {
        latestEFXCreditReport = creditReportData;
      }

      return dispatch({
        type: 'CREDIT_REPORT',
        credit_report: latestEFXCreditReport,
        latest_3B_credit_report: latest3BCreditReport,
        previous_EFX_credit_report: previousEFXCreditReport,
        previous_3B_credit_report: previous3BCreditReport,
      });
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      return dispatch({type: 'CREDIT_REPORT_STATUS', credit_report_status: 'error', credit_report_error: error});
    }
  };
}

export function downloadCreditReport(reportid: $TSFixMe, jwt: JWT) {
  return (dispatch: $TSFixMe) => {
    dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'loading'});
    const equifaxApiKey = _get(jwt, 'vendorInfo.equifaxApiKey');
    const equifaxEndPoint = _get(jwt, 'vendorInfo.equifaxEndPoint');
    const jwtToken = _get(jwt, 'jwtToken');

    if (!equifaxApiKey || !equifaxEndPoint || !jwtToken) {
      //TODO: We need error case specific for printing credit report
      return null;
    }
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiClient'.
    return EfxConsumerApiClient({
      apiKey: equifaxApiKey,
      server: equifaxEndPoint,
      clientAssertion: jwtToken,
    }).then(
      (clientLibraryInstance: $TSFixMe) => {
        dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'success'});
        return clientLibraryInstance.getDownloadCreditReportPDFLink(reportid);
      },
      (error: $TSFixMe) => {
        dispatch({type: 'DOWNLOAD_REPORT_STATUS', download_report_status: 'error', download_report_error: error});
      }
    );
  };
}

export function getCreditReportAbstractEquifax(clientLibraryInstance: $TSFixMe) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});

    try {
      const response = await clientLibraryInstance.getCreditReportAbstract();

      dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: response});
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_ABSTRACT_STATUS',
        credit_report_abstract_status: 'error',
        credit_report_abstract_status_error: error,
      });
    }
  };
}

export function getCreditReportAbstractMock(accountGuid: $TSFixMe) {
  const startTime = Date.now();
  return async(dispatch: $TSFixMe) => {
    dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'loading'});

    try {
      const response = await getMockCreditReportHistory(accountGuid);

      dispatch({type: 'CREDIT_REPORT_ABSTRACT', credit_report_abstract: response});
      dispatch({type: 'CREDIT_REPORT_ABSTRACT_STATUS', credit_report_abstract_status: 'success'});
    } catch (error) {
      utils.log(buildEfxLogInfo(error, startTime));
      dispatch({
        type: 'CREDIT_REPORT_ABSTRACT_STATUS',
        credit_report_abstract_status: 'error',
        credit_report_abstract_status_error: error,
      });
    }
  };
}

// Define the new method
async function handleEquifaxClient(
  tokenObject: any,
  dispatch: any,
  localizedStringBundle: any,
  langCode: string,
  is3BUser: boolean,
  creditPullInfo: any
) {
  const startTime = Date.now();

  const venderInfoScope = _get(tokenObject, 'vendorInfo.scope');
  const equifaxApiKey = _get(tokenObject, 'vendorInfo.equifaxApiKey');
  const equifaxEndPoint = _get(tokenObject, 'vendorInfo.equifaxEndPoint');
  const jwtToken = _get(tokenObject, 'jwtToken');
  const scope =
    venderInfoScope === 'delivery'
      ? // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
        EfxConsumerApiConstants.ApiChannel.DELIVERY
      : // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiConstants'.
        EfxConsumerApiConstants.ApiChannel.PROXY_DELIVERY;
  if ((!equifaxApiKey || !equifaxEndPoint || !jwtToken) && !window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
    dispatch({type: 'EQUIFAX_STATUS', equifax_status: 'error'});
    return null;
  }

  try {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'EfxConsumerApiClient'.
    const clientLibraryInstance = await EfxConsumerApiClient({
      scope,
      apiKey: equifaxApiKey,
      server: equifaxEndPoint,
      clientAssertion: jwtToken,
    });

    dispatch(getCreditScoreEquifax(clientLibraryInstance, localizedStringBundle, langCode));
    //dispatch(getCreditReportAbstractEquifax(clientLibraryInstance));
    dispatch(getCreditScoreHistoryEquifax(clientLibraryInstance));
    dispatch(getCreditReportEquifax(clientLibraryInstance, localizedStringBundle, langCode, is3BUser, creditPullInfo));
  } catch (error) {
    utils.log({
      feature: 'Credit',
      actionType: `EFX client call - ${equifaxEndPoint}`,
      message: error,
      statusCode: 401,
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      elapsedTime: new Date() - startTime,
      clientId: window.isDwm ? 'dwm' : 'dsp',
    });
    dispatch({type: 'EQUIFAX_STATUS', equifax_status: 'error', equifax_error: error});
  }
}

// Replace the original code with a call to the new method

export function equifaxClient(
  tokenObject: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  is3BUser: $TSFixMe,
  creditPullInfo: $TSFixMe
) {
  if (window.REACT_APP_ENABLE_MOCK_CREDIT_TOOL) {
    return async(dispatch: $TSFixMe, getState: $TSFixMe) => {
      const {accountGuid, useCreditMockEndpoints = false} = await getAccountGuidAndMockEndpointStatus(getState());

      if (useCreditMockEndpoints) {
        dispatch(getCreditScoreMock(accountGuid, localizedStringBundle, langCode));
        //dispatch(getCreditReportAbstractMock(accountGuid));
        dispatch(getCreditScoreHistoryMock(accountGuid));
        dispatch(getCreditReportMock(accountGuid, localizedStringBundle, langCode, is3BUser, creditPullInfo));
      } else {
        handleEquifaxClient(tokenObject, dispatch, localizedStringBundle, langCode, is3BUser, creditPullInfo);
      }
    };
  }
  return async(dispatch: $TSFixMe) => {
    handleEquifaxClient(tokenObject, dispatch, localizedStringBundle, langCode, is3BUser, creditPullInfo);
  };
}

// Exported wrapper used solely by credit.test.js to mock equifaxClient when testing
// callEfx.
export const creditLib = {
  equifaxClient,
};

export const callEfx = (
  accountId: $TSFixMe,
  localizedStringBundle: StringMap,
  langCode: $TSFixMe,
  is3BUser: $TSFixMe,
  creditPullInfo: $TSFixMe,
  jwt: JWT
) => {
  return (dispatch: $TSFixMe) => {
    return dispatch(creditLib.equifaxClient(jwt, localizedStringBundle, langCode, is3BUser, creditPullInfo));
  };
};

// Credit On Demand
export function setCreditOnDemand() {
  return {
    type: CREDIT_ON_DEMAND,
  };
}

function getCreditPullInfoRequest() {
  return {
    type: CREDIT_PULLINFO_REQUEST,
  };
}

function getCreditPullInfoFailure(error: $TSFixMe) {
  return {
    type: CREDIT_PULLINFO_FAILURE,
    error,
  };
}

function getCreditPullInfoSuccess(pullInfo: $TSFixMe) {
  // TODO: We wish to mock the following from the memx-tool credit report tool
  /*pullInfo = {
    isFulfilled1BReportAvailable: true,
    isFulfilled3BReportAvailable: true,
    isPull1BReportAllowed: false,
    isPull1BScoreAllowed: false,
    isPull3BReportAllowed: false,
    nextAvailable1BReportPullDate: 1724230800000,
    nextAvailable1BScorePullDate: 1724490000000,
    nextAvailable3BReportPullDate: 1716581351000,
  };*/

  return {
    type: CREDIT_PULLINFO_SUCCESS,
    pullInfo,
  };
}

export function getCreditPullInfo() {
  const url = utils.getCreditPullDatePath();
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  return api(url, config, getCreditPullInfoRequest(), getCreditPullInfoSuccess, getCreditPullInfoFailure);
}

function getFulfillmentRequest(topTabId: string) {
  const reportType = topTabId === 'EFX' ? '1B' : '3B';
  return {
    type: CREDIT_FULFILLMENT_REQUEST,
    reportType,
  };
}

function getFulfillmentSuccess(fulfillmentInfo: $TSFixMe) {
  return {
    type: CREDIT_FULFILLMENT_SUCCESS,
    fulfillmentInfo,
  };
}

function getFulfillmentFailure(error: $TSFixMe) {
  return {
    type: CREDIT_FULFILLMENT_FAILURE,
    error,
  };
}

export function getCreditFulfillment(
  bureau: string,
  features: $TSFixMe,
  topTabId: string,
  method: HttpMethod = 'POST'
) {
  const url = utils.getCreditReportPath(bureau);

  const config: FetchConfig = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
    body: JSON.stringify({
      features,
    }),
  };

  return api(url, config, getFulfillmentRequest(topTabId), getFulfillmentSuccess, getFulfillmentFailure);
}

export function clearFulfillmentInfo() {
  return {
    type: CLEAR_FULFILLMENT_INFO,
  };
}

//TransUnion
function getReportHistoryRequest() {
  return {
    type: TU_REPORT_HISTORY_REQUEST,
  };
}

function getReportHistorySuccess(reportHistory: $TSFixMe, localizedStringBundle: StringMap, langCode: $TSFixMe) {
  return {
    type: TU_REPORT_HISTORY_SUCCESS,
    reportHistoryScoreTrend: reportHistory.scoreHistory,
    reportHistory: reportHistory.history,
    newReportAllowed: reportHistory.newReportAllowed,
    nextAvailablePullDate: reportHistory.nextAvailablePullDate,
    nextAvailablePullDateAfterToday: reportHistory.nextAvailablePullDateAfterToday,
    previousScore: reportHistory.previousScore,
    previousScoreDate: reportHistory.previousScoreDate,
    localizedStringBundle,
    langCode,
  };
}

function getReportHistoryFailure(error: $TSFixMe) {
  return {
    type: TU_REPORT_HISTORY_FAILURE,
    error,
  };
}

export function getTuCreditReportHistory(bureau: string, localizedStringBundle: StringMap, langCode: string) {
  const url = utils.getCreditReportHistoryPath(bureau);
  const config: FetchConfig = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  return api(
    url,
    config,
    getReportHistoryRequest(),
    (reportHistory: $TSFixMe) => getReportHistorySuccess(reportHistory, localizedStringBundle, langCode),
    getReportHistoryFailure
  );
}

function getReportRequest() {
  return {
    type: TU_REPORT_REQUEST,
  };
}

function getReportSuccess(report: $TSFixMe, localizedStringBundle: StringMap, langCode: $TSFixMe) {
  return {
    type: TU_REPORT_SUCCESS,
    report,
    localizedStringBundle,
    langCode,
  };
}

function getReportFailure(error: $TSFixMe) {
  const errorCode = _get(error, 'code', '');
  return {
    type: TU_REPORT_FAILURE,
    errorCode,
  };
}

export function getTuCreditReport(
  bureau: string,
  localizedStringBundle: StringMap,
  langCode: string,
  method: HttpMethod = 'GET'
) {
  const url = utils.getCreditReportPath(bureau);

  const config: FetchConfig = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': utils.getCookieValue(document.cookie, 'XSRF-TOKEN'),
    },
    credentials: 'include',
  };

  return api(
    url,
    config,
    getReportRequest(),
    (report: $TSFixMe) => getReportSuccess(report, localizedStringBundle, langCode),
    getReportFailure
  );
}

/**
 * Helper function to build up EFX error logging info
 *
 * @param error
 * @param startTime
 * @returns {Object}
 */
function buildEfxLogInfo(error: $TSFixMe, startTime: $TSFixMe) {
  const elapsedTime = Date.now() - startTime;
  const errorMessage = utils.tryParseJson(_get(error, 'responseText'));
  const statusCode = _get(error, 'status', '');
  const efxEndpoint = _get(error, 'responseURL', '');
  return {
    feature: 'Credit',
    actionType: `EFX client call - ${efxEndpoint}`,
    message: errorMessage ? errorMessage.error : '',
    statusCode,
    elapsedTime,
    clientId: window.isDwm ? 'dwm' : 'dsp',
  };
}
