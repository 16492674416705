import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {Institution} from '../reducers/transactionReducers';
import {getBrowserLanguage, getLangCode, interpolate} from '../stringBundle';
import utils from '../helpers/utils';
import * as institutionStates from '../constants/institutionStates';
import dateUtils from './dateUtils';

/**
 * Given the date and the number of months to subtract the method returns the new date
 * @param {Date} inputDate
 * @param {number} numOfMonthsToSubtract
 * @returns {Date} updatedDate
 */
export const subtractMonths = (inputDate: Date = new Date(), numOfMonthsToSubtract: number): Date => {
  return new Date(inputDate.getFullYear(), inputDate.getMonth() - numOfMonthsToSubtract, inputDate.getDate());
};

/**
 * Given the date and number of months to subtract return the monthYearString
 * @param {Date} inputDate
 * @param {number} numOfMonthsToSubtract
 * @returns {string} monthYearString
 */
export const convertDateToGraphKey = (inputDate: Date = new Date(), numOfMonthsToSubtract: number): string => {
  const browserLanguage = getBrowserLanguage();
  const convertedDate = subtractMonths(inputDate, numOfMonthsToSubtract);
  const year = convertedDate.getUTCFullYear();
  const shortMonth = convertedDate.toLocaleString(browserLanguage, {month: 'short'}).toUpperCase();
  return `${shortMonth}-${year}`;
};

/**
 * Returns a boolean value to show if the response to institution accounts call contains non-empty institutionList array
 *
 * @param response - response to institution accounts call
 * @returns {boolean}
 */
export function hasAvailableInstitution(response: object) {
  const institutionsList: Institution[] = _get(response, 'institutionsList', []);
  if (
    _isEmpty(institutionsList) ||
    !Array.isArray(institutionsList) ||
    !_isEmpty(_get(institutionsList, 'noResults', ''))
  ) {
    return false;
  }
  return true;
}

/**
 * For the given institutionState value, produce an error message with the given institutionName and errorCode
 * values inserted.  Returns an empty string if the institution status is "ACTIVE" (no error).
 *
 * @param {string} institutionState - error state of insitution
 * @param {string} institutionName - name of institution
 * @param {object} localizedStringBundle - string bundle containing localized messages
 * @param {string} errorCode - error code associated with institution error
 * @returns {string}
 */
export const getRefreshErrorMessage = (
  institutionState: string,
  institutionName: string,
  localizedStringBundle: StringMap,
  errorCode?: string,
  consentExpirationDate?: string
): string => {
  let errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_RECONNECT;
  let supportUrl = window.REACT_APP_TXM_RECONNECT_SUPPORT_URL;

  if (institutionState && institutionState.startsWith('MT_')) {
    supportUrl = window.REACT_APP_CSP_TXM_JP_SUPPORT_URL;
  }

  switch (institutionState) {
    case 'ACTIVE':
      return '';
    case 'CREDENTIAL_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_CREDENTIAL_ERROR;
      break;
    case 'UPDATE_CANCELLED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_UPDATE_CANCELLED;
      break;
    case 'SITE_CREDENTIAL_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_CREDENTIAL_ERROR;
      break;
    case 'AUTH_SITE_CREDENTIAL_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_AUTH_SITE_CREDENTIAL_ERROR;
      break;
    case 'MAX_ATTEMPT_SITE_CREDENTIAL_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_MAX_ATTEMPT_SITE_CREDENTIAL_ERROR;
      break;
    case 'SITE_UPDATED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_UPDATED;
      break;
    case 'PERSONAL_DETAILS_SITE_UPDATED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_PERSONAL_DETAILS_SITE_UPDATED;
      break;
    case 'PROMOTIONAL_SITE_UPDATED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_PROMOTIONAL_SITE_UPDATED;
      break;
    case 'UNSUPPORTED_LANG_SITE_UPDATED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_UNSUPPORTED_LANG_SITE_UPDATED;
      break;
    case 'SITE_NOT_SUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_NOT_SUPPORTED;
      break;
    case 'AUTH_SITE_NOT_SUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_AUTH_SITE_NOT_SUPPORTED;
      break;
    case 'REQUEST_TIMEOUT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_REQUEST_TIMEOUT;
      break;
    case 'SITE_REQUEST_TIMEOUT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_REQUEST_TIMEOUT;
      break;
    case 'AUTH_REQUEST_TIMEOUT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_AUTH_REQUEST_TIMEOUT;
      break;
    case 'LOGIN_REQUEST_TIMEOUT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_LOGIN_REQUEST_TIMEOUT;
      break;
    case 'SITE_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_ERROR;
      break;
    case 'SITE_TECHNICAL_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_TECHNICAL_ERROR;
      break;
    case 'SITE_UNAVAILABLE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_UNAVAILABLE;
      break;
    case 'BETA_SITE_WORK_IN_PROGRESS':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_BETA_SITE_WORK_IN_PROGRESS;
      break;
    case 'EXPIRED_UAR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_EXPIRED_UAR;
      break;
    case 'MULTIPLE_LOGIN_UAR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_MULTIPLE_LOGIN_UAR;
      break;
    case 'NEW_INFORMATION_NEEDED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_NEW_INFORMATION_NEEDED;
      break;
    case 'OB_NO_CONSENT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_OB_NO_CONSENT;
      break;
    case 'INVALID_OB_NO_TOKEN':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_INVALID_OB_NO_TOKEN;
      break;
    case 'TECHNICAL_ERROR_OB_NO_TOKEN':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_TECHNICAL_ERROR_OB_NO_TOKEN;
      break;
    case 'UNKNOWN_SITE_NOT_SUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_SITE_NOT_SUPPORTED;
      break;
    //FL4 upgrade
    case 'FL4_ADDL_AUTHENTICATION_REQUIRED':
    case 'FL4_INVALID_ADDL_INFO_PROVIDED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_ADDL_INFO_REQUIRED;
      break;
    case 'FL4_ACCOUNT_LOCKED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_ACCOUNT_LOCKED;
      break;
    case 'FL4_BETA_SITE_DEV_IN_PROGRESS':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_BETA_SITE_DEV_IN_PROGRESS;
      break;
    case 'FL4_CONNECTION_INTERMEDIATE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_CONNECTION_INTERMEDIATE;
      break;
    case 'FL4_CONSENT_ERROR':
    case 'FL4_INCORRECT_OAUTH_TOKEN':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_CONSENT_TEXT;
      break;
    case 'FL4_CREDENTIALS_UPDATE_NEEDED':
    case 'FL4_INCORRECT_CREDENTIALS':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_CREDENTIALS_TEXT;
      break;
    case 'FL4_DATA_NOT_AVAILABLE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_DATA_NOT_AVAILABLE;
      break;
    case 'FL4_DATA_RETRIEVAL_FAILED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_DATA_RETRIEVAL_FAILED;
      break;
    case 'FL4_REQUEST_TIME_OUT':
    case 'FL4_SITE_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_SITE_ERROR;
      break;
    case 'FL4_SITE_NOT_SUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_SITE_NOT_SUPPORTED;
      break;
    case 'FL4_SITE_SESSION_INVALIDATED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_SITE_SESSION_INVALIDATED;
      break;
    case 'FL4_TECH_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_TECH_ERROR;
      break;
    case 'FL4_USER_ACTION_NEEDED_AT_SITE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_USER_ACTION_NEEDED_AT_SITE;
      break;
    case 'FL4_CONSENT_RENEWAL_REQUIRED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_CONSENT_RENEWAL_REQUIRED;
      break;
    case 'MT_RUNNING_AUTH':
    case 'MT_RUNNING_DATA':
    case 'MT_RUNNING_INTELLIGENCE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_PROCESSING;
      break;
    case 'MT_SUCCESS':
      errorText = '';
      break;
    case 'MT_SUSPENDED_MISSING_ANSWER_AUTH_SECURITY':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_MISSING_ANSWER_SECURITY;
      break;
    case 'MT_SUSPENDED_MISSING_ANSWER_AUTH_OTP':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_MISSING_ANSWER_OTP;
      break;
    case 'MT_SUSPENDED_MISSING_ANSWER_AUTH_CAPTCHA':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_MISSING_ANSWER_CAPTCHA;
      break;
    case 'MT_SUSPENDED_MISSING_ANSWER_AUTH_PUZZLE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_MISSING_ANSWER_PUZZLE;
      break;
    case 'MT_AUTH_CREDS_CAPTCHA_INVALID':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_CAPTCHA_INVALID;
      break;
    case 'MT_AUTH_CREDS_PUZZLE_INVALID':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_PUZZLE_INVALID;
      break;
    case 'MT_AUTH_CREDS_OTP_INVALID':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_OTP_INVALID;
      break;
    case 'MT_AUTH_CREDS_INVALID':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_CREDS_INVALID;
      break;
    case 'MT_AUTH_CREDS_SECURITY_INVALID':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_SECURITY_INVALID;
      break;
    case 'MT_AUTH_CREDS_LOCKED_TEMPORARY':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_CREDS_LOCKED_TEMPORARY;
      break;
    case 'MT_AUTH_CREDS_LOCKED_PERMANENT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_CREDS_LOCKED_PERMANENT;
      break;
    case 'MT_ERROR_PERMANENT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_PERMANENT;
      break;
    case 'MT_ERROR_TEMPORARY':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_TEMPORARY;
      break;
    case 'MT_ERROR_SESSION':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_SESSION;
      break;
    case 'MT_ERROR_SERVICE_UNAVAILABLE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_SERVICE_UNAVAILABLE;
      break;
    case 'MT_ERROR_NETWORK':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_NETWORK;
      break;
    case 'MT_ERROR_UNSUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_UNSUPPORTED;
      break;
    case 'MT_ERROR_WONT_SUPPORT':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_ERROR_UNSUPPORTED;
      break;
    case 'MT_GUEST_INTERVENTION_REQUIRED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_GUEST_INTERVENTION_REQUIRED;
      break;
    case 'MT_INACTIVE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_INACTIVE;
      break;
    case 'MT_UNDER_MAINTENANCE':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_JP_UNDER_MAINTENANCE;
      break;
    default:
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_RECONNECT;
      break;
  }

  return interpolate(errorText, {
    institutionName,
    errorCode,
    supportUrl,
    consentExpirationDate,
  });
};

/**
 * Returns cta button text for a financial institution
 *
 * @param {boolean} isTxmJpEnabled - is user txm jp enabled
 * @param {string} institutionCta - cta text to display
 * @param {stringMap} localizedStringBundle - An object map with localized (translated) strings
 * @returns {string}
 */
export const getCtaText = (institutionCta: string, localizedStringBundle: StringMap): string => {
  let ctaText = '';
  switch (institutionCta) {
    case 'resolve': {
      ctaText = localizedStringBundle.RESOLVE;
      break;
    }
    case 'update': {
      ctaText = localizedStringBundle.UPDATE;
      break;
    }
    case 'view_details': {
      ctaText = localizedStringBundle.VIEW_DETAILS;
      break;
    }
    case 'remove': {
      ctaText = localizedStringBundle.REMOVE;
      break;
    }
    case 'reconnect': {
      ctaText = localizedStringBundle.RECONNECT;
      break;
    }
    default: {
      break;
    }
  }
  return ctaText;
};

/**
 * get text to display for a particular auto-refresh-state value for an institution-account
 */
export const getAutoRefreshMessage = (
  autoRefreshState: string,
  institutionName: string,
  errorCode: string,
  localizedStringBundle: StringMap,
  countryCode: string
): string => {
  let errorText = '';
  let supportUrl = window.REACT_APP_TXM_RECONNECT_SUPPORT_URL;

  if (countryCode === 'jp') {
    supportUrl = window.REACT_APP_CSP_TXM_JP_SUPPORT_URL;
  }
  switch (autoRefreshState) {
    // DISABLED/DELAYED use same message
    case 'DISABLED_ADDL_AUTHENTICATION_REQUIRED':
    case 'DELAYED_ADDL_AUTHENTICATION_REQUIRED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_REFRESH_DISABLED_AUTH_REQUIRED;
      break;
    case 'DISABLED_TECH_ERROR':
    case 'DELAYED_TECH_ERROR':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_REFRESH_DISABLED_TECH_ERROR;
      break;
    case 'DISABLED_USER_ACTION_NEEDED':
    case 'DELAYED_USER_ACTION_NEEDED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_REFRESH_DISABLED_USER_ACTION;
      break;
    case 'DISABLED_SITE_DISABLED':
    case 'DELAYED_SITE_DISABLED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_REFRESH_DISABLED_TECH_ERROR;
      break;
    // Auto Refresh Messages for FL4
    case 'DISABLED_TEMP_ERROR':
    case 'DISABLED_SITE_BLOCKING':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_SITE_ERROR;
      break;
    case 'DISABLED_SITE_NOT_SUPPORTED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_SITE_NOT_SUPPORTED;
      break;
    case 'DISABLED_REAL_TIME_MFA_REQUIRED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_ADDL_INFO_REQUIRED;
      break;
    case 'DISABLED_USER_ACTION_REQUIRED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_USER_ACTION_REQUIRED;
      break;
    case 'DISABLED_UNSUBSCRIBED':
      errorText = localizedStringBundle.MANAGE_ACCOUNT_TXM_MON_FL4_DISABLED_UNSUBSCRIBED;
      break;
    // for ALL other cases, no message should be displayed, so an empty string is returned
    case 'UNKNOWN_AUTO_REFRESH_STATE':
    case 'ENABLED_SCHEDULED':
    default:
      errorText = '';
      break;
  }
  return interpolate(errorText, {
    institutionName,
    errorCode,
    supportUrl,
  });
};

/**
 * Gets a list of institutions from institutionList
 * @param {Institution | Institution[] | string} institutionsList - an instition, a list of institution, or a string indicating institution status
 * @returns {Institution[]} Returns an array of inactive institution
 */
export function checkInactiveInstitutions(institutionsList: Institution | Institution[] | string) {
  const inactiveInstitutions = [];

  if (Array.isArray(institutionsList) && !_isEmpty(institutionsList)) {
    institutionsList.forEach((institution) => {
      if (institutionStates.INSTITUTION_INACTIVE_STATES.indexOf(institution.institutionState) !== -1) {
        inactiveInstitutions.push(institution);
      }
    });
  } else if (
    typeof institutionsList === 'object' &&
    !_get(institutionsList, 'noResults') &&
    institutionStates.INSTITUTION_INACTIVE_STATES.indexOf(_get(institutionsList, 'institutionState')) !== -1
  ) {
    inactiveInstitutions.push(institutionsList);
  }
  return inactiveInstitutions;
}

/**
 * Gets an array of inactive institution objects
 * @param {Institution | Institution[] | string} institutionsList - an instition, a list of institution, or a string indicating institution status
 * @param {StringMap} localizedStringBundle - language string bundle map
 * @param {boolean} isTxmJpEnabled - a boolean value to indicate if the user is txm jp enabled
 * @param {Function} handleClickReconnect - an handler function upon Reconnect click
 * @param {Function} handleClickRemove - an handler function upon Remove click
 * @param {string} whiteLabelClient - a string of white label client
 * @returns {Institution[]} Returns an array of inactive institution objects
 */
export function getInactiveInstitutions(
  institutionsList: Institution | Institution[] | string,
  localizedStringBundle: StringMap,
  isTxmJpEnabled = false,
  handleClickReconnect: Function,
  handleClickRemove: Function,
  whiteLabelClient: string
) {
  const inactiveInstitutions = checkInactiveInstitutions(institutionsList);
  const compiledInactiveInstitutions: BannerItem[] = [];
  inactiveInstitutions.forEach((institution) => {
    const itemObj = buildInactiveInstitutionItemObj(
      institution,
      isTxmJpEnabled,
      handleClickReconnect,
      handleClickRemove,
      localizedStringBundle,
      whiteLabelClient
    );
    compiledInactiveInstitutions.push(itemObj);
  });
  return compiledInactiveInstitutions;
}

/**
 * Builds an inactive institution object
 * @param {Institution | Institution[] | string} institutionsList - an instition, a list of institution, or a string indicating institution status
 * @param {boolean} isTxmJpEnabled - boolean value to indicate if user is a TxmJP enabled
 * @param {Function} handleClickReconnect - handler function for reconnect click
 * @param {Function} handleClickRemove - handler function for remove click
 * @param {StringMap} localizedStringBundle - language string bundle map
 * @returns {Institution[]} Returns an inactive institution object
 */
export function buildInactiveInstitutionItemObj(
  institution: Institution | Institution[] | string,
  isTxmJpEnabled: boolean,
  handleClickReconnect: Function,
  handleClickRemove: Function,
  localizedStringBundle: StringMap,
  whiteLabelClient: string
) {
  const institutionCta: string = _get(institution, 'institutionCta', '');
  const institutionState: string = _get(institution, 'institutionState', '');
  const accountGroup: string = _get(institution, 'accountGroup', '');
  const errorCode: string = _get(institution, 'messageCode', '');

  let buttonObj: BannerActionButton;
  let itemObj: BannerItem;
  let message: string, action: string, handleClick: Function;

  message = getRefreshErrorMessage(
    institutionState,
    _get(institution, 'institutionDisplayName', ''),
    localizedStringBundle,
    errorCode
  );
  action = getCtaText(_get(institution, 'institutionCta', ''), localizedStringBundle);

  const showReconnectModal =
    action === localizedStringBundle.RECONNECT || ['resolve', 'update', 'view_details'].includes(institutionCta);
  handleClick = (e: React.MouseEvent<HTMLElement>) => {
    return showReconnectModal
      ? handleClickReconnect(institution, e, accountGroup)
      : handleClickRemove(
          _get(institution, 'institutionId', ''),
          _get(institution, 'memSiteAccountId', ''),
          e,
          accountGroup
        );
  };

  buttonObj = {label: action, click: handleClick};
  itemObj = {
    icon: utils.getCdnImagesPath('/dsp-northstar/TriangleWarning', whiteLabelClient),
    message,
    button: buttonObj,
  };
  return itemObj;
}

/**
 * Gets an array of inactive institution objects
 * @param {Institution | Institution[] | string} institutionsList - an institution, a list of institution, or a string indicating institution status
 * @param {StringMap} localizedStringBundle - language string bundle map
 * @param {Function} handleClickReconnect - an handler function upon Reconnect click
 * @param {Function} handleClickRemove - an handler function upon Remove click
 * @param {string} whiteLabelClient - Identifies the white label client associated with the current user.
 * @param {string} countryCode - user countryCode
 * @returns {Institution[]} Returns an array of inactive institution objects
 */
export function getInactiveInstitutionsByAccounts(
  institutionsList: Institution[],
  localizedStringBundle: StringMap,
  handleClickReconnect: Function,
  handleClickRemove: Function,
  whiteLabelClient: string,
  countryCode: string
) {
  // Array to store unique inactive institutions
  const uniqueInactiveInstitutions: BannerItem[] = [];
  if (Array.isArray(institutionsList) && !_isEmpty(institutionsList)) {
    institutionsList.forEach((institution: Institution) => {
      const itemObj = renderErrorMessage(
        institution,
        handleClickReconnect,
        handleClickRemove,
        localizedStringBundle,
        whiteLabelClient,
        countryCode
      );
      // Only push if itemObj is defined and not empty
      if (itemObj) {
        uniqueInactiveInstitutions.push(itemObj);
      }
    });
  }
  return uniqueInactiveInstitutions;
}

/**
 * Builds an inactive institution object with the error or auto refresh message and button with an action
 * @param {Institution | Institution[] | string} institutionsList - an institution, a list of institution, or a string indicating institution status
 * @param {Function} handleClickReconnect - handler function for reconnect click
 * @param {Function} handleClickRemove - handler function for remove click
 * @param {StringMap} localizedStringBundle - language string bundle map
 * @param {string} whiteLabelClient - Identifies the white label client associated with the current user.
 * @param {string} countryCode - user countryCode
 * @returns {InactiveInstitutionItem[]} Returns an inactive institution object
 */
export function renderErrorMessage(
  institution: Institution,
  handleClickReconnect: Function,
  handleClickRemove: Function,
  localizedStringBundle: StringMap,
  whiteLabelClient: string,
  countryCode: string
) {
  let itemObj: BannerItem;
  let message: string, action: string, handleClick: Function;

  const institutionCta: string = _get(institution, 'institutionCta', '');
  const autoRefreshState: string = _get(institution, 'autoRefreshState', '');
  const institutionState: string = _get(institution, 'institutionState', '');
  const accountGroup: string = _get(institution, 'accountGroup', '');
  const errorCode: string = _get(institution, 'messageCode', '');
  const institutionName: string = _get(institution, 'institutionDisplayName', '');
  const rawConsentExpirationDate = _get(institution, 'consent.expirationDate', '') || '';
  const consentExpirationDate = rawConsentExpirationDate
    ? dateUtils.convertDateToSlashFormat(rawConsentExpirationDate, getLangCode(), '-')
    : '';
  const autoRefreshErrorText = getAutoRefreshMessage(
    autoRefreshState,
    institutionName,
    errorCode.toString(),
    localizedStringBundle,
    countryCode
  );
  const errorTextMessage = getRefreshErrorMessage(
    institutionState,
    institutionName,
    localizedStringBundle,
    errorCode.toString(),
    consentExpirationDate
  );
  message = errorTextMessage || autoRefreshErrorText;

  // Return undefined if message is empty
  if (!message) {
    return undefined;
  }
  itemObj = {
    icon: utils.getCdnImagesPath('/dsp-northstar/TriangleWarning', whiteLabelClient),
    message,
  };
  action = errorTextMessage
    ? getCtaText(_get(institution, 'institutionCta', ''), localizedStringBundle)
    : localizedStringBundle.RECONNECT;
  if (action) {
    const showReconnectModal =
      action === localizedStringBundle.RECONNECT || ['resolve', 'update', 'view_details'].includes(institutionCta);
    handleClick = (e: React.MouseEvent<HTMLElement>) => {
      return showReconnectModal
        ? handleClickReconnect(institution, e, accountGroup)
        : handleClickRemove(
            _get(institution, 'institutionId', ''),
            _get(institution, 'memSiteAccountId', ''),
            e,
            accountGroup
          );
    };
    itemObj.button = {label: action, click: handleClick};
  }
  return itemObj;
}
