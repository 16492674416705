import {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import VectorImage from '../../components/common/VectorImage';
import Image from '../../components/common/Image';
import utils from '../../helpers/utils';
import {IntlContext} from '../../containers/IntlProvider';

type DropdownProps = {
  setSelected?: $TSFixMeFunction; // Function that runs when an option is selected
  list: $TSFixMe[]; // list of options for the dropdown
  selectedName?: $TSFixMe; // selected value of the dropdown
  menuMobile?: boolean; // determines whether the dropdown is mobile menu
  isMenu?: boolean; // determines whether the dropdown is a menu or not
  label?: string; // label value for the dropdown
  helperText?: string; // helper text value to be shown below the dropdown
  disabled?: boolean; // determines whether dropdown disabled or enabled
  dataTestId?: $TSFixMe; // test classes for the dropdown and dropdown options
  headerTitle?: string;
  whiteLabelClient?: string; // Identifies white label client associated with the current user. Used to select partner-specific image.
  size?: string;
  newFilterUi?: boolean;
};

/**
 * Renders Dropdown component
 * @param {DropdownProps}
 * @returns {JSX.Element}
 */
const Dropdown = ({
  setSelected,
  list = [],
  isMenu = false,
  selectedName = {},
  menuMobile = false,
  label,
  helperText = '',
  disabled = false,
  dataTestId = {},
  headerTitle,
  whiteLabelClient = '',
  size = 'medium',
  newFilterUi = false,
}: DropdownProps) => {
  const [prevItem, setPrevItem] = useState<{key: string; index: number}>({
    key: '',
    index: 0,
  });
  const [listVisible, setListVisible] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const localizedStringBundle: StringMap = useContext(IntlContext);
  const tmpSelectedName = menuMobile ? localizedStringBundle.MENU : _get(selectedName, 'name', '');

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * toggles dropdown options onClick
   */
  const toggle = () => {
    if (disabled) {
      return;
    }
    setListVisible(!listVisible);
  };

  /**
   * sets state of the variable onClick
   */
  const select = (item: $TSFixMe) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelected(item);
  };

  /**
   * Handles onClick event on the dropdown options
   */
  const handleOnClick = (item: $TSFixMe) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelected(item);
    toggle();
  };

  /**
   * Handles onClick event anywhere outside of dropdown
   */
  const handleClickOutside = (e: $TSFixMe) => {
    if (dropdownRef.current && (dropdownRef.current as $TSFixMe).contains(e.target)) {
      return;
    }
    setListVisible(false);
  };

  /**
   * Handles onFocus for dropdown
   */
  const handleOnFocus = () => {
    if (disabled) {
      return;
    }
    setFocus(true);
  };

  /**
   * Handles onBlur for dropdown
   */
  const handleOnBlur = () => {
    setFocus(false);
  };

  /**
   * Handles onKeyPress event when dropdown list is visible (selects the option and move it to the top of dropdown options based on key pressed)
   * @param {KeyboardEvent} e - event value when a key is pressed on the keyboard
   */
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let potentialIndex = 0;
    const key = e.key.toUpperCase();
    const dropdown = e.target as HTMLElement;

    if (prevItem && prevItem.key === key) {
      prevItem.index = prevItem.index + 1;
    } else {
      prevItem.index = 0;
    }

    if (dropdown) {
      let dropdownOptions: NodeListOf<Element> = dropdown.querySelectorAll('.list');
      if (!dropdownOptions || dropdownOptions.length === 0) {
        dropdownOptions = (dropdown as $TSFixMe).parentElement.parentElement.querySelectorAll('.list');
      }

      if (dropdownOptions && dropdownOptions.length > 0) {
        const items = dropdownOptions[dropdownOptions.length - 1].querySelectorAll('li');

        if (items && items.length > 0) {
          let selectedOption;
          const selectedCharArray = [];
          for (let i = 0; i < items.length; i++) {
            if (items[i].innerHTML.indexOf(key) === 0) {
              selectedCharArray.push(items[i]);
            }
          }

          if (selectedCharArray.length > 0) {
            potentialIndex = prevItem.index % selectedCharArray.length;
            selectedOption = selectedCharArray[potentialIndex];

            if (selectedOption) {
              const index = selectedOption.getAttribute('data-id');

              if (index !== null) {
                select(list[parseInt(index)]);
              }
              if (selectedOption.parentElement && selectedOption.parentElement.parentElement) {
                selectedOption.parentElement.parentElement.scrollTop = selectedOption.offsetTop;
              }
            }

            setPrevItem({
              key: key,
              index: potentialIndex,
            });
          }
        }
      }
    }
  };

  /**
   * Render list of dropdown options
   * @returns {JSX.Element[]} - list of dropdown options
   */
  const renderListItems = () => {
    return (
      Array.isArray(list) &&
      list.map((item, index) => {
        const isOnTopEdgeOfMenu = index === 0;
        const isOnBottomEdgeOfMenu = index === list.length - 1;
        const automationSelector = (item.name || '').replace(/[^A-Z0-9]+/gi, '').toLowerCase();
        const rowClass = classNames(
          selectedName.value && selectedName.value === item.value && item.name === selectedName.name
            ? 'h-auto px-4 py-4 bg-option-hover'
            : 'h-auto px-4 py-4',
          (!selectedName.value && item.name === selectedName.name) ||
            (selectedName.value && item.value === selectedName.value)
            ? newFilterUi
              ? 'font-normal'
              : 'font-bold'
            : '',
          menuMobile
            ? 'font-medium hover:bg-brand-primary hover:text-primary hover:font-semibold'
            : 'bg-option hover:bg-option-hover text-primary hover:text-button-text',
          size === 'small' ? 'flex items-center' : 'block',
          {
            'bg-option-hover': !selectedName.value && item.name === selectedName.name,
            'flex items-center': item.logo,
            'hover:bg-action': item.logo && !menuMobile,
            'rounded-t': menuMobile && isOnTopEdgeOfMenu,
            'rounded-b': menuMobile && isOnBottomEdgeOfMenu,
          }
        );
        const iconClasses = classNames('w-6', {
          'h-6 mr-2': menuMobile,
          'pt-1 mr-4': !menuMobile,
        });
        const logo = item.logo && (
          <span>
            <Image url={item.logo} classes={iconClasses} key={item.logo} />
          </span>
        );
        const name = item.name && <span className="my-auto mr-4">{item.name}</span>;
        const supportContent = item.supportComponent && (
          <span className="my-auto ml-auto mr-2">{item.supportComponent}</span>
        );

        return (
          <li
            data-id={index}
            onClick={() => handleOnClick(item)}
            key={item.name}
            className={`test-${automationSelector} ${rowClass}`}
            data-testid={item.iconName}
          >
            {logo}
            {logo ? name : item.name}
            {supportContent}
          </li>
        );
      })
    );
  };

  /**
   * Renders header with logo.
   * @param {string} titleStr - Title in header
   * @param {string} whiteLabelClient - Identifies the white label client associated with the current user. Used to select partner-specific image.
   * @returns {JSX.Element} returns JSX for the header
   */
  function renderHeader(titleStr: string, whiteLabelClient: string) {
    // Partner images will be hosted in nortonLifelock CDN repo
    const partnerCdnPath = utils.getCdnImagesPath('/logo', whiteLabelClient);
    const nortonLifelockCdnPath = utils.getCdnImagesPath('/dsp-northstar/LifelockLogo', whiteLabelClient);

    return (
      <div className="flex w-full justify-center">
        <div className="mr-2 h-8 w-8 min-w-8">
          <VectorImage alt="logo" url={whiteLabelClient ? partnerCdnPath : nortonLifelockCdnPath} dataTestId="logo" />
        </div>
        <h2 className="mb-2 mt-1 break-all text-xl font-bold tracking-normal text-primary">{titleStr}</h2>
      </div>
    );
  }

  function renderActiveMonitoring() {
    return (
      <div className="my-2 flex justify-center self-center">
        <div className="mx-2 my-auto">
          <VectorImage
            url={utils.getCdnImagesPath('/dsp-northstar/GreenCheck', whiteLabelClient)}
            classes={'w-4 h-4'}
            alt={localizedStringBundle.DARK_WEB_ACTIVE_MONITORING}
          />
        </div>
        <span className="ml-1 mt-0.5 align-middle text-xs font-extrabold text-safe">
          {localizedStringBundle.DARK_WEB_ACTIVE_MONITORING}
        </span>
      </div>
    );
  }

  const dropdownContainerClasses = classNames(
    'relative md:max-w-xs',
    disabled ? 'bg-input-background' : newFilterUi && selectedName.value !== '' ? 'bg-action' : 'bg-surface',
    menuMobile ? 'w-fit my-auto' : 'rounded-md mb-2 md:mb-0 flex-grow',
    size === 'small' ? 'w-44 mb-0' : ''
  );
  const arrowIconClasses = classNames(
    'material-icons text-2xl right-0 inset-y-0',
    menuMobile ? 'ml-1' : 'mt-4 mr-2 absolute',
    disabled
      ? 'text-secondary-disabled'
      : newFilterUi && selectedName.value !== ''
      ? 'text-surface'
      : listVisible || focus || hover
      ? menuMobile
        ? 'text-primary'
        : 'text-action'
      : 'text-primary'
  );
  const listClasses = classNames(
    'list break-words z-50 rounded top-0 left-0 cursor-pointer custom-scrollbar custom-scrollbar-thumb bg-background',
    menuMobile
      ? 'w-auto shadow-lg -mx-30 sm:-mx-20 bg-background mt-10 pl-4 sm:left-20 fixed h-fit sm:h-full pr-4'
      : 'shadow-heavy w-full max-h-72 overflow-y-auto mt-12 absolute',
    !listVisible ? 'hidden' : ''
  );
  const dropdownClasses = classNames(
    'h-21 flex items-center w-full break-words pl-4 cursor-pointer',
    newFilterUi && selectedName.value !== '' ? 'text-surface py-1' : 'text-primary py-2',
    !menuMobile ? 'rounded-md leading-normal shadow-inner' : '',
    disabled
      ? 'bg-input-background border-input-disabled-stroke'
      : (listVisible || focus || hover) && !menuMobile
      ? 'border-action'
      : menuMobile
      ? 'bg-nav'
      : newFilterUi && selectedName.value !== ''
      ? 'bg-action'
      : 'bg-surface border-input-stroke',
    !listVisible && focus && !tmpSelectedName ? 'border-4' : !menuMobile ? 'border-2' : '',
    size === 'small' ? 'pr-7' : 'pr-6'
  );

  const selectedNameClasses = classNames(
    'truncate',
    label ? 'mt-2' : '',
    disabled ? 'text-secondary-disabled' : newFilterUi && selectedName.value != '' ? 'text-surface' : 'text-primary',
    isMenu ? 'text-right mb-1 font-bold' : '',
    tmpSelectedName ? 'pt-0.5' : 'mb-6'
  );
  const labelClassNames = classNames(
    'absolute top-0 left-0 font-medium cursor-text pl-4',
    tmpSelectedName || listVisible ? 'pt-1 text-xs mt-1' : 'pt-4 text-base',
    disabled ? 'text-secondary-disabled' : listVisible || focus ? 'text-action' : 'text-secondary'
  );
  const helperTextClassNames = 'mt-2 text-xs text-primary';
  const listTagClasses = classNames(menuMobile ? 'bg-nav mb-4' : 'bg-surface list-reset');
  const headerContent = menuMobile && (
    <div className="pt-5" data-testid="mobile-menu-header">
      {headerTitle && renderHeader(headerTitle, whiteLabelClient)}
      {renderActiveMonitoring()}
    </div>
  );

  return (
    <div
      className={dropdownContainerClasses}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onClick={toggle}
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={dropdownRef}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      data-testid={dataTestId?.dropdown ? dataTestId.dropdown : ''}
    >
      <div className={dropdownClasses}>
        <div className={selectedNameClasses} data-testid={dataTestId?.selectedName ? dataTestId.selectedName : ''}>
          {tmpSelectedName}
        </div>
        {menuMobile && (
          <VectorImage
            url={utils.getCdnImagesPath(
              listVisible ? '/dsp-northstar/expand-less' : '/dsp-northstar/expand-more',
              whiteLabelClient
            )}
            classes="ml-2 w-4 h-6"
            alt={listVisible ? 'expand-less' : 'expand-more'}
          />
        )}
        {!menuMobile && <i className={arrowIconClasses}>{listVisible ? 'arrow_drop_up' : 'arrow_drop_down'}</i>}
      </div>
      <div className={listClasses} data-testid={dataTestId?.list ? dataTestId.list : ''}>
        {headerContent}
        <ul className={listTagClasses} data-testid={menuMobile ? 'mobile-menu-dropdown-list' : 'dropdown-list'}>
          {renderListItems()}
        </ul>
      </div>

      {label && (
        <label className={labelClassNames} data-testid={`label-${label}`}>
          {label}
        </label>
      )}

      {helperText && <div className={helperTextClassNames}>{helperText}</div>}
    </div>
  );
};

export default Dropdown;
