import classNames from 'classnames';
import {REACT_EVENT_KEY_ENTER} from '../../constants/eventConstants';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'danger' | 'safe';
  id?: string;
  size?: 'small' | 'medium' | 'large';
  handleClick?: $TSFixMeFunction;
  trackEvent?: $TSFixMeFunction;
  handleKeyDown?: $TSFixMeFunction;
};

/**
 * Renders the button component
 * @param {string} size - size of the button
 * @param {string} variant - the color palette of the button.
 * @param {string} id - optional unique component level id
 * @param {HTMLElement} children - button child component.
 * @param {boolean} disabled - flag to disable the button.
 * @param {Function} handleClick - The callback that handles the button click.
 * @param {Function} trackEvent - The callback that handles the track event of the button.
 * @returns {JSX.Element} Returns markup for a button
 */
function Button({
  size = 'medium',
  variant = 'primary',
  children,
  disabled = false,
  handleClick,
  trackEvent,
  id,
  handleKeyDown,
}: Props) {
  /** handles the track event and the button click
   */
  const handler = (e: $TSFixMe) => {
    if (trackEvent) {
      trackEvent();
    }
    if (handleClick) {
      handleClick(e);
    }
  };

  const keyboardEventHandler = (e: $TSFixMe) => {
    if (e.key === REACT_EVENT_KEY_ENTER && handleKeyDown) {
      handleKeyDown(e);
    }
    return;
  };

  const buttonClasses = {
    base: 'rounded-full self-center font-bold text-center',
    disabled:
      'bg-button-disabled border-2 border-button-disabled-stroke font-bold cursor-not-allowed text-secondary-disabled',
    size: {
      small: 'border-2 px-2 py-1 w-full sm:min-w-32 h-10 text-sm',
      medium: 'border-4 px-4 py-2 w-full sm:min-w-44 h-12 text-base',
      large: 'border-4 px-2 py-4 w-full sm:min-w-44 h-14 text-lg',
    },
    variant: {
      primary: 'btn-focus-primary button-text bg-brand-primary hover:bg-brand-primary-hover border-button-stroke',
      secondary: 'btn-focus-secondary text-primary bg-surface hover:bg-primary hover:text-surface border-primary',
      danger: 'btn-focus-danger border-none text-surface bg-danger',
      safe: 'btn-focus-safe border-none text-surface bg-safe',
    },
  };
  const buttonGroupClass = classNames(buttonClasses.size[size], buttonClasses.base, {
    [`${buttonClasses.disabled}`]: disabled,
    [`${buttonClasses.variant[variant]}`]: !disabled,
  });

  return (
    <button
      disabled={disabled}
      className={buttonGroupClass}
      onClick={handler}
      {...{id}}
      data-testid={id}
      onKeyDown={keyboardEventHandler}
    >
      {children}
    </button>
  );
}
export default Button;
